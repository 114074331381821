<template>
  <div>
    <div class="box-div" v-for="(item, index) in listData" :key="index">
      <div class="title">{{item.title}}
        <div style="display: flex;    align-items: baseline;" @click="openClick(item.title)">
          <div v-if="forItem(item.title)">
            {{returnItem(item.title)}}
          </div>
          <div style="color: #A6A6A6" v-else>
            请选择
          </div>
          <!--        <div>-->
          <van-icon name="arrow" style="margin-left: 5px" color="#A7A7A7" size="17px"/>
          <!--        </div>-->
        </div>
      </div>
    </div>

    <van-popup @click-overlay="closePopup(item.title)" v-for="(item, index) in listData" :key="index + '&'" v-model="item.openStatus" position="bottom">
      <van-picker
          :title="item.title"
          show-toolbar
          :columns="item.columns"
          @confirm="(value, eIndex) => selectSubmit(value, eIndex, item.title)"
          @cancel="closePopup(item.title)"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "FormulaListSix",
  props: {
    listData: {
      type: Array,
      required: true,
    },
    selectValue: {
      type: [Array],
      required: true
    }
  },
  data () {
    return {
      list: [],
    }//dataReturn
  },
  watch: {
    selectValue: {
      immediate: true, // 立即执行一次
      handler(newValue) {
        this.list = [...newValue];
      },
    },
  },
  methods: {
    selectSubmit (value, eIndex, title) {
      const index = this.list.findIndex((e) => e.title === title)
      if (index !== -1) {
        this.list[index].value = value
        this.$set(this.list, index, this.list[index])
      } else {
        this.list.push({
          title,
          value: value
        })
      }
      const indexData = this.listData.findIndex((e) => e.title === title)
      if (indexData !== -1) {
        delete this.listData[indexData].openStatus
        this.$set(this.listData, indexData, this.listData[indexData])
      }
      this.$emit('change', this.list)
    },
    returnItem (title) {
      const text = this.list.filter(row => row.title === title)[0]
      if (text) {
        return text.value
      } else {
        return  ''
      }
    },
    forItem (title) {
      return this.list.some(e => e.title === title)
    },
    openClick (title) {
      const index = this.listData.findIndex((e) => e.title === title)
      if (index !== -1) {
        if (this.listData[index].openStatus) {
          this.listData[index].openStatus = false
          this.$set(this.listData, index, this.listData[index])
        } else {
          this.listData[index].openStatus = true
          this.$set(this.listData, index, this.listData[index])
        }
      }
    },
    closePopup (title) {
      const index = this.listData.findIndex((e) => e.title === title)
      if (index !== -1) {
        delete this.listData[index].openStatus
        this.$set(this.listData, index, this.listData[index])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
  .title {
    display: flex;
    justify-content: space-between;
  }
}
</style>