<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <FormulaListSix :list-data="listData" :select-value="listSelect" @change="sixChange"></FormulaListSix>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
        </div>
      </div>
      <CalculationResults :countText="countText" :countTextList="countTextList" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import FormulaListSix from "@/components/Template6/FormulaListSix";
import CalculationResults from "@/components/CalculationResults.vue";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextList:[],
      listSelect: [],
      listData: [
        {
          title: '警觉程度',
          columns: ['深睡眠', '浅睡眠', '嗜睡', '完全清醒和警觉', '高度警觉'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '平静或激动',
          columns: ['平静', '轻度焦虑', '焦虑', '非常焦虑', '惊恐'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '呼吸反应',
          columns: ['无咳嗽或无自主呼吸', '偶有自主呼吸，对机械通气无对抗', '偶有咳嗽或人机对抗', '人机对抗活跃，频繁咳嗽', '严重人机对抗、咳嗽、憋气'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '身体活动',
          columns: ['无自主活动', '偶有轻微活动', '频繁的轻微活动', '四肢有力活动', '躯干及头部有力活动'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '血压',
          columns: ['低于基础值', '始终在基础值', '偶尔升高>15%(观察期间1~3次)', '频繁升高>15%(>3次)', '持续升高>15%'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '心率',
          columns: ['低于基础值', '始终在基础值', '偶尔升高>15%(观察期间1~3次)', '频繁升高>15%(>3次)', '持续升高>15%'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '肌张力',
          columns: ['肌肉完全放松，没有张力', '肌张力减低', '肌张力正常', '肌张力增加，手指和脚趾弯曲', '肌肉极度僵硬，手指和脚趾弯曲'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '面部紧张程度',
          columns: ['面部肌肉完全放松', '面部肌肉张力正常，无面部肌肉紧张', '面部部分肌肉张力增加', '面部全部肌肉张力增加', '面部扭曲，表情痛苦'],
          fenzhi: [1,2,3,4,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      selectValueSix: [],
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['正常或轻伤', '中度创伤', '重度创伤'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
    }
  },
  components: {
    CalculationResults,
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    FormulaListSix
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    sixChange (data) {
      this.listSelect = data
      this.show=false
    },
    coumputTetxt () {
      this.show=true
      let fenshu = 0
      function calculateScore(data, select) {
        const index = data.columns.indexOf(select);
        if (index !== -1) {
          fenshu += data.fenzhi[index];
        }
      }

      this.listSelect.forEach(value => {
        const data = this.listData.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value.value);
        }
      });

      this.result = fenshu
      if (fenshu >= 8 && fenshu <= 16) {
        this.countText = `${fenshu}分`+' 深度镇静'
      } else if (fenshu >= 17 && fenshu <= 26) {
        this.countText = `${fenshu}分`+' 轻度镇静'
      } else if (fenshu >= 27 && fenshu <= 40) {
        this.countText =`${fenshu}分`+' 镇静不足'
      }
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'QBFMIBRU',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
      
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.listSelect = res.data.content.data
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.coumputTetxt()
        }
      }
    },
    async submit () {
      for (let i = 0; i < this.listData.length; i++) {
        const status = this.listSelect.some(item => item.value && item.title === this.listData[i].title)
        if (!status) {
          Toast.fail(`请选择${this.listData[i].title}`)
          return
        }
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countText
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}

</style>
